import { LeafletProvider, useLeafletContext } from "@react-leaflet/core";
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Layer } from "leaflet";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ListItem, SvgIconProps } from "@mui/material";

interface MenuProps {
  checked?: boolean;
  children: ReactNode;
  name: string;
  title?: string;
  icon: React.ReactElement<SvgIconProps>;
}
export const MenuLayer: FunctionComponent<MenuProps> = (props: MenuProps) => {
  const { checked, children, name, icon } = props;
  const parentContext = useLeafletContext();
  const [active, setActive] = useState<boolean>(false);
  const [layer, setLayer] = useState<Layer | null>(null);

  useEffect(() => {
    if (checked) {
      setActive(true);
    }
  }, [checked]);

  const { map } = parentContext;

  const addLayer = useCallback(
    (layerToAdd: Layer) => {
      if (checked) {
        map.addLayer(layerToAdd);
      }
      setLayer(layerToAdd);
    },
    [map, checked]
  );
  const removeLayer = useCallback(() => {
    return;
  }, []);
  const addLayerButton = () => {
    if (layer !== null) {
      map.addLayer(layer);
    }
  };

  const removeLayerButton = () => {
    if (layer !== null) {
      map.removeLayer(layer);
    }
  };

  const changeLayer = () => {
    if (!active) {
      addLayerButton();
    } else {
      removeLayerButton();
    }
    setActive(!active);
  };
  const context = useMemo(
    () => ({ ...parentContext, layerContainer: { addLayer, removeLayer } }),
    [parentContext, addLayer, removeLayer]
  );

  const renderChildren = () => {
    const child = React.Children?.map(children, (child) => {
      return React.cloneElement(child as any, {
        name: name,
      });
    });
    return child;
  };

  return children ? (
    <>
      <LeafletProvider value={context}>{renderChildren()}</LeafletProvider>
      <ListItem
        disableGutters={true}
        sx={{ paddingLeft: "6px" }}
        button
        onClick={changeLayer}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </>
  ) : null;
};

export default MenuLayer;
