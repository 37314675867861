import React, { useEffect, useRef, useState } from "react";
import aerodromosRepositories from "../../repositories/Aerodromos";
import { icon, LatLngExpression } from "leaflet";
import { Marker, useMapEvents } from "react-leaflet";
import { Typography } from "@mui/material";
import nl2br from "react-nl2br";
import { IResponse } from "../../repositories/models";
import ButtonLegend from "../../components/ButtonLegend";
import TemporaryDrawer from "../../components/TemporaryDrawer";
import LegendStatus from "./components/LegendStatus";
import LineLegend from "../../components/LineLegend";
import Box from "@mui/material/Box";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import useLayerChecked from "../../hooks/useLayerChecked";

const paises = "brasil";

const Green = "/assets/images/aerodromos/green.svg";
const GreenW = "/assets/images/aerodromos/greenw.svg";
const Red = "/assets/images/aerodromos/red.svg";
const RedW = "/assets/images/aerodromos/redw.svg";
const Yellow = "/assets/images/aerodromos/yellow.svg";
const YellowW = "/assets/images/aerodromos/yelloww.svg";
const Cinza = "/assets/images/aerodromos/cinza.svg";

const columnsLegend = ["Visiblidade", "Condição", "Teto(ft)", "Cor"];
const dataLegend = [
  [">=5000", "e", ">=1500", "/assets/images/aerodromos/twogreen.svg"],
  [
    "<5000 e >= 1500",
    "e/ou",
    "<1500 e >= 600",
    "/assets/images/aerodromos/twoyellow.svg",
  ],
  ["<1500 metros", "e/ou", "<600ft", "/assets/images/aerodromos/twored.svg"],
];
const layer = "Aerodromos";
const Aerodromos = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [sizeIcon, setSizeIcon] = useState<L.PointExpression>([12, 12]);
  const [infoAer, setInfoAer] = useState<any>([]);
  const [openAer, setOpenAer] = useState<boolean>(false);
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });

  useEffect(() => {
    subscribed.current = true;
    getStatus();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const ZoomEvent = () => {
    useMapEvents({
      zoom(e) {
        const size = e.sourceTarget._zoom * 4;
        setSizeIcon([size, size]);
      },
    });
  };

  function iconColor(color: string) {
    let iconMarkup;
    switch (color) {
      case "g":
        iconMarkup = Green;
        break;
      case "gw":
        iconMarkup = GreenW;
        break;
      case "r":
        iconMarkup = Red;
        break;
      case "rw":
        iconMarkup = RedW;
        break;
      case "y":
        iconMarkup = Yellow;
        break;
      case "yw":
        iconMarkup = YellowW;
        break;
      case "cinza":
        iconMarkup = Cinza;
        break;
      default:
        iconMarkup = "";
    }
    const customMarkerIcon = icon({
      iconUrl: iconMarkup,
      iconSize: sizeIcon,
    });
    return customMarkerIcon;
  }
  const getStatus = async () => {
    try {
      const { data } = await aerodromosRepositories.status<IResponse<any>>(
        paises
      );
      if (subscribed.current) {
        setData(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const toggleDrawerAer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenAer(open);
    };
  if (!checkedLayer) {
    return null;
  }
  return (
    <>
      {ZoomEvent}
      {data?.map((coor, key) => {
        const position: LatLngExpression = [coor[2] as any, coor[3] as any];
        return (
          <Marker
            key={key}
            position={position}
            icon={iconColor(coor[4])}
            eventHandlers={{
              click: () => {
                setInfoAer(coor);
                setOpenAer(true);
              },
            }}
          />
        );
      })}
      <ButtonLegend onClick={toggleDrawer(true)} position={"topright"}>
        <AirplanemodeActiveIcon />
      </ButtonLegend>
      <TemporaryDrawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
      >
        <LineLegend columns={columnsLegend} data={dataLegend} />
        <LegendStatus />
      </TemporaryDrawer>
      <TemporaryDrawer
        open={openAer}
        onClose={toggleDrawerAer(false)}
        anchor={"bottom"}
      >
        <Box sx={{ padding: 2 }}>
          <Typography
            style={{ fontWeight: "bold" }}
            variant={"subtitle2"}
            gutterBottom
          >
            {infoAer[0]} - {infoAer[1]}
          </Typography>
          <Typography variant={"body2"}>{nl2br(infoAer[5])}</Typography>
        </Box>
      </TemporaryDrawer>
    </>
  );
};

export default Aerodromos;
