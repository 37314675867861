import React, { useEffect, useRef, useState } from "react";
import { Circle, ImageOverlay, Popup } from "react-leaflet";
import produtosRepositories, {
  RadarDadosType,
  RadarType,
} from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import { Typography } from "@mui/material";
import ButtonLegend from "../../components/ButtonLegend";
import TemporaryDrawer from "../../components/TemporaryDrawer";
import GradientLegend from "../../components/GradientLegend";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import useLayerChecked from "../../hooks/useLayerChecked";

const paramsDefault = "maxcappi";
const layer = "Radar";
const Radar = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<RadarDadosType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });
  useEffect(() => {
    subscribed.current = true;
    getData(paramsDefault);
    return () => {
      subscribed.current = false;
    };
  }, []);

  const getData = async (parameters = paramsDefault) => {
    try {
      const { data } = await produtosRepositories.radar<IResponse<RadarType>>(
        parameters
      );
      if (subscribed.current) {
        if (data.data.radar !== undefined) {
          setData(data.data.radar[0] as any);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  if (!checkedLayer) {
    return null;
  }

  return (
    <>
      {data.length > 0 &&
        data.map((row: RadarDadosType, index: number) => {
          const color = row.path === null ? "#721c24a3" : "#999";
          return (
            <>
              <ImageOverlay
                key={`image-${index}`}
                bounds={[
                  [row.lat_min, row.lon_min],
                  [row.lat_max, row.lon_max],
                ]}
                url={
                  row.path === null
                    ? "https://estatico-redemet.decea.mil.br/radar/nodata.png"
                    : row.path
                }
              />
              <Circle
                key={index}
                stroke={false}
                color={color}
                center={[row.lat_center as number, row.lon_center as number]}
                radius={row.raio * 1000}
              >
                <Popup>
                  <Typography variant="subtitle2" gutterBottom>
                    {row.nome}
                  </Typography>
                  <Typography variant="overline" display="block" gutterBottom>
                    {row.data ? row.data : "Não disponível"}
                  </Typography>
                </Popup>
              </Circle>
            </>
          );
        })}
      <ButtonLegend onClick={toggleDrawer(true)} position={"topright"}>
        <SettingsInputAntennaIcon />
      </ButtonLegend>
      <TemporaryDrawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
      >
        <GradientLegend
          title="Refletividade (dBZ)"
          endTitle="Potencial de Precipitação (mm/h)"
          topLabels={["M. Fraco", "Fraco", "Moderado", "Forte", "M.Forte"]}
          valuesList={[
            { label: "0", color: ["#75FFFF", "#0000F6"], scale: 1 },
            { label: "1", color: ["#00FF00", "#009900"], scale: 1 },
            { label: "3", color: ["#FFFF00", "#FF9900"], scale: 1 },
            { label: "25", color: ["#FF0000", "#C40001"], scale: 1 },
            { label: ">100", color: ["#FF00FF", "#9758C9"], scale: 1 },
          ]}
          valuesListTop={[
            { value: "0", scale: 1, label: "M. Fraco" },
            { value: "20", scale: 1, label: "Fraco" },
            { value: "30", scale: 1, label: "Moderado" },
            { value: "45", scale: 1, label: "Forte" },
            { value: "63", scale: 1, label: "" },
            { value: "75", scale: 1, label: "M. Forte" },
          ]}
        />
      </TemporaryDrawer>
    </>
  );
};

export default Radar;
